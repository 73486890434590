<template>
  <div class="bg">
    <v-tooltip v-if="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.lgAndUp">
      <template v-slot:activator="{ on, attrs }">
        <router-link to="/">
          <v-btn
              fab
              fixed
              top
              left
              dark
              medium
              color="orange"
              class="floating-button"
              elevation="16"
          >
            <v-icon v-bind="attrs" v-on="on" medium>mdi-home</v-icon>
          </v-btn>
        </router-link>
      </template>
      <span>Back To Home</span>
    </v-tooltip>
    <v-tooltip v-if="$vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly">
      <template v-slot:activator="{ on, attrs }">
        <router-link to="/">
          <v-btn
              fab
              fixed
              top
              left
              dark
              small
              color="orange"
              class="floating-button"
              elevation="16"
          >
            <v-icon v-bind="attrs" v-on="on" small>mdi-home</v-icon>
          </v-btn>
        </router-link>
      </template>
      <span>Kembali Ke Home</span>
    </v-tooltip>
    <div class="video-background">
      <div class="overlay"></div>
    </div>
    <v-row>
      <v-col cols="12" sm="3" md="3"> </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-flex class="mt-10 mb-2 mx-4">
          <v-card elevation="24" class="mx-auto glass-bg" max-width="500">
            <v-card-title class="white--text">
              <v-spacer />
              LOGIN
              <v-spacer />
            </v-card-title>
            <v-card-text>
              <v-text-field
                  v-model="user.username"
                  label="NIP/email (User name)"
                  hide-details="auto"
                  prepend-inner-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                  v-model="user.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  label="Password"
                  hide-details="auto"
                  @click:append="show1 = !show1"
                  @keydown.enter="handleLogin"
              ></v-text-field>
            </v-card-text>

            <v-container v-if="errorMessage">
              <div class="red--text text-center font-italic">{{ errorMessage }}</div>
            </v-container>

            <div class="d-flex mt-n1 mr-4">
              <v-spacer></v-spacer>
              <span class="ps-4 black--text subtitle-2">
                <router-link to="/reset-atur-ulang">
                  <span class="font-italic highlight-on-hover"
                        :class="errorMessage?'blue--text subtitle-1 font-weight-bold':'black--text text--lighten-4' ">Lupa password?</span>
                </router-link>
              </span>
            </div>
            <v-card-actions class="ma-4">
              <v-spacer />
              <v-btn color="primary" @click="handleLogin" width="200px"
              >Login</v-btn
              >
              <v-spacer />
            </v-card-actions>
            <v-card-actions>
            <span class="ps-4 black--text">Do not have an account?
              <router-link to="/select-account-type" style="text-decoration: none !important;">
                <span style="text-decoration: none" class="blue--text font-weight-bold ml-1 subtitle-1 highlight-on-hover">Daftar Akun Baru Disini</span>
              </router-link>
            </span>

            </v-card-actions>
          </v-card>
        </v-flex>
      </v-col>
      <v-col cols="12" sm="3" md="3"> </v-col>
    </v-row>
  </div>
</template>

<script>
import User from "../models/user";
import ERole from "@/models/e-role";
// import UserService from "@/services/user-service";

export default {
  name: "LoginView",
  components: {},
  data() {
    return {
      show1: false,
      user: new User("", ""),
      loading: false,
      message: "",
      encryptedFmeetingBean: '',
      errorMessage: ''
    };
  },
  computed: {
    currentUserWebinar(){
      if(this.$store.state.auth.user){
        if(this.$store.state.auth.user.roles.includes(ERole.ROLE_USER)){
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn && !this.currentUserWebinar) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin() {
      this.errorMessage = ''
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          const fromactive = this.$route.params.fromactive
          this.$store.dispatch("auth/login", this.user).then(
              () => {
                if(this.currentUserWebinar && fromactive !== '1'){
                  if (this.encryptedFmeetingBean) {
                    this.$router.push("/webinar-detail?"+this.encryptedFmeetingBean);
                  } else {
                    this.$router.push("/home");
                  }
                } else {
                  this.$router.push("/home");
                }

              },
              (error) => {
                this.errorMessage = 'Username dan password anda tidak cocok'
                this.loading = false;
                this.message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
              }
          );
        }
      });
    },
  },
  mounted() {
    this.encryptedFmeetingBean = this.$route.query.fmeetingBean;

  }
};
</script>
<style scoped>
.glass-bg {
  background: rgba(255, 255, 255, 0.45);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.overlay {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100vh;
  z-index: 1;
  opacity: 0.85;
}
.video-background {
  background-image: url("../../public/assets/carousel/carousel-1.jpg");
  background-repeat: no-repeat; /* Optional: Do not repeat the image */
  background-size: cover; /* Optional: Cover the entire container */
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}
.floating-button {
  z-index: 99;
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
}
.bg {
  background: transparent;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  /*background: url( 'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg') no-repeat center center;*/
  /* background-size: cover; */
  /*background-image: url( 'https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg'), linear-gradient(to bottom right, #f7d96a 10%, transparent 90%);*/
  /* background-image: linear-gradient(
    to bottom right,
    floralwhite 50%,
    transparent 90%
  ); */
  /*transform: scale(1.1);*/
}
/*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/
/*background-color: lightyellow;*/
/*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/

.footer_image {
  background-image: url("https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"),
    linear-gradient(#eb01a5, #d13531);
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
